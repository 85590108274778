import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasBusinessUsers,
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getLockedTransactions } from "selectors/transactions";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import classNames from "classnames";
import moment from "moment";
import useTransactionUtils from "hooks/useTransactionUtils";

const { STATUSES: { NEED_REACTION, EXPORTED, TO_RECONCILE } } = DataConstants;

const Actions = (props) => {
  const {
    selected,
    selectable,
    readyToProcess,
    readyToApprove,
    readyToReview,
    transaction,
    transaction: { id: transactionId, aiProcessing, status, timestamp },
    transactionState,
    transactionState: { manualMode },
    refetchTableData,
    onClose,
    onSelectedChange,
    onManualModeSet
  } = props;

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const { extraData: { integrationServiceConnected } = {} } = useSelector(getSelectedBusinessData);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const { businessOrganization } = useSelector(getActiveOrganization);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const bookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const transactionLocked = !!useSelector(getLockedTransactions)[transactionId];

  const businessUser = useSelector(checkIsBusinessUser);

  const { onTransactionApplyChanges } = useTransactionUtils();

  const needReactionStatus = status === NEED_REACTION;

  const allowAskClient = selectable && (!businessOrganization || selectedBusinessHasBusinessUsers);

  const closedBookDate = bookCloseDate && moment(timestamp).isSameOrBefore(moment(bookCloseDate));

  const warning = closedBookDate && (quickBooksBusiness || status !== EXPORTED);

  const disableApplyButton = useMemo(() => {
    if (status === EXPORTED) {
      return !quickBooksBusiness || !integrationServiceConnected || !Transactions.hasChanges(transactionState, transaction);
    }

    return rpaMode ? (aiProcessing || (!readyToProcess && !readyToApprove)) : !readyToApprove;
  }, [
    aiProcessing,
    quickBooksBusiness,
    readyToApprove,
    readyToProcess,
    rpaMode,
    integrationServiceConnected,
    status,
    transaction,
    transactionState
  ]);

  const handleSaveButtonClick = useCallback(async() => {
    onClose();

    const result = await onTransactionApplyChanges(transaction, transactionState);

    if (result) refetchTableData();
  }, [transaction, transactionState, refetchTableData, onTransactionApplyChanges, onClose]);

  const handleAskClientClick = useCallback(async() => {
    if (needReactionStatus) {
      onClose();
      await dispatch(TransactionsActions.bulkTransactionsUpdate({
        ids: [transactionId],
        data: { status: TO_RECONCILE }
      }));
      refetchTableData();
    } else {
      onSelectedChange(transactionId, !selected);
    }
  }, [needReactionStatus, dispatch, transactionId, onSelectedChange, selected, refetchTableData, onClose]);

  if (businessUser) {
    return (
      <div className={Css.actions}>
        <Button
          large
          icon={Icons.PaperPlaneTilt}
          primary={readyToReview}
          warning={warning}
          disabled={!readyToReview || transactionLocked}
          onClick={handleSaveButtonClick}>
          {uiTexts.sendToReview}
        </Button>
      </div>
    );
  }

  if (rpaMode) {
    return (
      <div className={Css.actions}>
        {needReactionStatus
          ? (!aiProcessing && !manualMode && (
            <Button
              outline
              icon={Icons.PencilSimple}
              disabled={transactionLocked}
              onClick={onManualModeSet}>
              {manualMode ? uiTexts.enterComment : uiTexts.enterManually}
            </Button>
          ))
          : (allowAskClient && (
            <Button
              outline
              data-id={transactionId}
              warning={!selected}
              danger={selected}
              disabled={transactionLocked}
              icon={selected ? Icons.X : Icons.Question}
              onClick={handleAskClientClick}>
              {selected ? uiTexts.doNotAsk : uiTexts.askClient}
            </Button>
          ))}
        <Button
          outline
          disabled={disableApplyButton || transactionLocked}
          primary={!disableApplyButton && needReactionStatus}
          success={!disableApplyButton && (!needReactionStatus || manualMode)}
          className={classNames(aiProcessing && Css.processing)}
          icon={aiProcessing ? Icons.Spinner : (needReactionStatus && !manualMode ? Icons.Robot : Icons.Check)}
          onClick={handleSaveButtonClick}>
          <span>
            {aiProcessing
              ? uiTexts.processing
              : (needReactionStatus && !manualMode
                ? uiTexts.aiCategorize : uiTexts.approve)}
          </span>
        </Button>
      </div>
    );
  }

  return (
    <div className={Css.actions}>
      {allowAskClient && (
        <Button
          outline
          data-id={transactionId}
          warning={!selected && !needReactionStatus}
          danger={selected || needReactionStatus}
          icon={(selected || needReactionStatus) ? Icons.X : Icons.Question}
          onClick={handleAskClientClick}>
          {(selected || needReactionStatus) ? uiTexts.doNotAsk : uiTexts.askClient}
        </Button>
      )}
      <Button
        outline
        disabled={(!warning && disableApplyButton) || transactionLocked}
        className={warning ? Css.warning : undefined}
        title={uiTexts.applyChanges}
        icon={warning ? Icons.Warning : Icons.PaperPlaneTilt}
        success={!warning && !disableApplyButton}
        onClick={handleSaveButtonClick}>
        {warning ? uiTexts.closedBookDate : uiTexts.applyChanges}
      </Button>
    </div>
  );
};

export default React.memo(Actions);
